import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Pagination from "../components/pagination"
import Article from "../components/article"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPagePath, totalPages, pageNumber },
}) => {
  const posts = data.allWpPost.nodes
  const categories = data.allWpCategory.nodes

  const filterCategories = categories.filter(category => category.posts.nodes.length)

  const metaTitle = pageNumber === 1 ? `Blog | Latest News and Stories` : `Blog | ${pageNumber} | Latest News and Stories`
  const metaDescription = pageNumber === 1 ? `tio Blog | Digital product news, stories, resources and helpful guides from tio.` : `tio Blog ${pageNumber} | Page ${pageNumber} Digital product news, stories, resources and helpful guides from tio.`

  if (!posts.length) {
    return (
      <Layout navbarTheme="dark" className="bg-cream">
        <Seo title={metaTitle} description={metaDescription} canonical="https://tio.ist/blog/" />
        <p>No blog posts found.</p>
      </Layout>
    )
  }


  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title={metaTitle} description={metaDescription} canonical="https://tio.ist/blog/" />
      <div className="py-10">
        <div className="text-blue pt-10">
          <h1 className="text-6xl md:text-8xl lg:text-9xl font-extrabold text-center">
            LATEST NEWS
          </h1>
          {/*  <div className="ticker-wrap-title w-full flex items-center overflow-hidden box-content bg-transparent text-6xl md:text-8xl lg:text-9xl font-light italic text-blue">
            <div className="ticker z-10 flex box-content whitespace-nowrap">
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
            </div>
            <div className="ticker z-10 flex box-content whitespace-nowrap">
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
              <div className="w-auto mx-16">
                <p>AND STORIES</p>
              </div>
            </div>
          </div> */}

          <div className="flex overflow-hidden text-blue text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="flex items-center flex-none min-w-[125%] md:min-w-full animate-scroll-left">
              <p>AND STORIES</p>
            </div>
            <div className="flex items-center flex-none min-w-[125%] md:min-w-full animate-scroll-left">
              <p>AND STORIES</p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-x-12 gap-y-6 mx-5 md:mx-16 pt-40">
          {filterCategories.map(category => <Link to={`/blog/category/${category.slug}`} key={category.id} className="text-blue uppercase font-bold text-sm" activeClassName="underline">{category.name}</Link>)}
        </div>

      </div>
      <div className="mx-2.5 md:mx-[3.375rem]">
        <ol className="lg:flex flex-wrap list-none">
          {posts.map(post => {
            const title = post.title
            const featuredImage = {
              data: post.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: post.featuredImage?.node?.alt || ``,
            }
            return (
              <Article post={post} title={title} featuredImage={featuredImage} />
            )
          })}
        </ol>
      </div>

      <Pagination
        rootSlug="/blog"
        totalPages={totalPages}
        previousPagePath={previousPagePath}
        nextPagePath={nextPagePath}
        currentPagePath={currentPagePath}
      />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive(
    $offset: Int!
    $postsPerPage: Int!
    $language: String!
  ) {
    allWpPost(
      filter: { language: { slug: { eq: $language } } }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    allWpCategory(filter: {language: {slug: {eq: $language}}, posts: {nodes: {elemMatch: {language: {slug: {eq: $language}}}}}}) {
      nodes {
        slug
        id
        name
        posts {
          nodes {
            id
            title
          }
        }
      }
    }
  }
`
